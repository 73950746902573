.footer {
    display: block;
    position: relative;
    background-color: #000;
    width: 100%;
    color: #fff;
    align-content: center;
    justify-content: center;
}

.footer .office {
    display: block;
    width: 420px;
    margin: 0 auto;
    padding: 30px;
}

.footer .office h1 {
    font-size: 20px;
    font-weight: 700;
}

.footer .office ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer .office ul li {
    margin-top: 20px;
    font-size: 18px;
}

.footer .office ul li i {
    width: 30px;
}
.footer .office ul li span{
    display: inline;
    width: 100%;
}

.footer img.fas {
    display: inline-block;
    width: 23px;
    height: 23px;
    vertical-align: bottom;
    margin-right: 10px;
}

.footer .copyright {
    width: 100%;
    background-color: #fff;
    color: #a4b2bd;
    padding: 50px 0;
    text-align: center;
}

.footer .copyright span {
    margin-right: 10px;
}

.footer .copyright a {
    text-decoration: none;
    color: #a4b2bd;
}

.footer .copyright a:hover {
    color: #175399;
}