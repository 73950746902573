
.layout-home {
    width: 100%;
    padding: 50px 0;
    
}
.about-home-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}
.about-home-layout-left {
    width: 100px;
    color: #222;
    font-size: 22px;
    font-weight: 400;
    padding: 50px;
}
.about-home-layout-right {
    flex:1 ;
    border-left: 1px solid #eee;
    padding: 0px 50px;
    color: #666;
    line-height: 2;
}

.divider {
    border-bottom: 1px solid #ebebeb;
}
.cert-layout {
    min-width: 576px;
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 auto;
}
.cert-layout h2 {
    font-size: 30px;
    color: #343a40;
    margin-bottom: 42px;
}
.cert-layout-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}
.cert-layout-item {
    width: 370px;
    margin: 0 20px;
}
.cert-layout-item-img {
    width: 100%;
}
.cert-layout-item-img img{
    width: 100%;
}
.cert-layout-item-content h4 {
    color: #343a40;
    font-weight: 500;
    font-size: 20px;
}