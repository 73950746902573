.layout-service {
    align-items: center;
    display: block; 
    background-color: #f5f5f5;
    margin: 0;
    padding: 30px;
}
.layout-service h1 {
    text-align: center;
    margin-bottom: 30px;
}
.layout-service-container {
    display: block;
    max-width: 1000px;
    margin: 0 auto;
}
.layout-service-list {
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;
}
.layout-service-list h1 {
    text-align: center;
}
.layout-service-element {
    width: 290px;
    height: 260px;
    display: block;
    overflow: hidden;
    padding: 5px;
    margin: 10px;
    background-color: #fff;
    border: 1px solid #e7e7e7;
}
.layout-service-element-title {
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    border-bottom: 1px solid #e7e7e7;
}
.layout-service-element-image {
    height: 210px;
}
.layout-service-element-image img {
    width: 100%;
    height: 100%;
}
.layout-service-element p {
    padding: 20px;
}