.about-layout {
    background-color: #f5f5f5;
    padding: 30px;
}

.about-layout h1 {
    text-align: center;
    margin-bottom: 30px;
}

.about-layout-container {
    max-width: 1000px;
    margin: 0 auto;
}

.about-layout-content {
    display: block;
}

.about-layout-container h1 {
    color: #222;
}

.about-layout-content p {
    display: block;
    padding: 16px 0;
    color: #1e334d;
    line-height: 24px;
    font-size: 16px;
}

.about-layout-content-list {
    display: block;
    margin-top: 45px;
    padding: 50px 0px;
    border-top: 1px solid #e5e5e5;
}

.about-layout-content-item {
    vertical-align: top;
    padding: 30px 0;
    border-bottom: 1px solid #e5e5e5;
}

.about-layout-content-item-title {
    display: inline-block;
    width: 300px;
    color: #333;
    
}
.about-layout-content-item-title img {
    display: inline-block;
    vertical-align: top;
    width: 30px;
    height: 30px;
}
.about-layout-content-item-title h2 {
    display: inline-block;
    font-style: normal;
    font-size: 26px;
    line-height: 26px;
    font-weight: 700;
}

.about-layout-content-item-detail {
    display: inline-block;
    vertical-align: top;
    color: #666;
}

.about-layout-content-item-detail p {
    color: #666;
}