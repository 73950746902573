.header {
    /* background-color: #6EC1E4; */
    background: linear-gradient(130deg, #0a0000 30%, #047dce 100%);
    text-align: center;
    padding: 16px;
}
.header .logo {
    width: 241px;
    height: 80px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}
.header .logo a {
    display: block;
    text-decoration: none;
}
.header .logo img {
    width: 100%;
    height: 100%;
    color: #fff;
}
.header .nav {
    display: inline-block;
}
.header .nav ul li {
    display: inline-block;
    margin: 0 10px;
}
.header .nav ul li a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}
.header .lang-selector {
    display: inline-block;
    color: #fff;
    margin-left: 30px;
}

.header .lang-selector span {
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
}
.header .lang-selector .lang-item.active {
    color: #e6231f;
}