#slider {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 800px;
}
#slider .slider-container {
    display: block;
    width: 100%;
    height: calc(100vh -67px);
    transition: transform 0.5s;
}
#slider .slider-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: opacity 0.5s;
    opacity: 0;
}
#slider .slider-image.active {
    opacity: 1;
}
#slider .overlay {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: rgba(0,0,0,0.5); */
    backdrop-filter: blur(0px);
    z-index:1;
}
#slider button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: block;
    align-items: center;
    justify-content: center;
    z-index: 2;
}
#slider .slider-arrow {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
}
#slider .slider-prev-button {
    left: 50px;
}
#slider .slider-prev-button .slider-arrow {
    transform: rotate(-135deg);
}
#slider .slider-next-button {
    right: 50px;
}
#slider .slider-next-button .slider-arrow {
    transform: rotate(45deg);
}
