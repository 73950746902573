.layout-newsdata {
    background-color: #fff;
    padding: 30px;
}
.layout-newsdata-title {
    text-align: center;
    margin-bottom: 30px;
}
.layout-newsdata-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 20px;
}
.layout-newsdata-item {
    display: block;
    border-bottom: 1px solid #eee;
    padding: 15px 0;
    background-color: #fff;
    max-width: 600px;
}
.layout-newsdata-item-pic {
    display: inline-block;
    height: 88px;
}
.layout-newsdata-item-pic a {
    display: block;
    width: 140px;
    height: 88px;
    overflow: hidden;
    text-decoration: none;
}
.layout-newsdata-item-pic a img {
    width: 100%;
}
.layout-newsdata-item-detail {
    display: inline-block;
    max-width: 445px;
    height: 88px;
    margin-left: 15px;
    vertical-align: top;
}
.layout-newsdata-item-title {
    display: block;
    height: 68px;
}
.layout-newsdata-item-title h3 {
    min-height: 10px;
    padding-bottom: 0px;
    max-width: 520px;
    text-decoration: none;
    font-style: normal;
    font-size: 20px;
    margin: 0;
}
.layout-newsdata-item-title h3 a {
    text-decoration: none;
    color: #404040;
    font-weight: normal;
    margin: 0;
}
.layout-newsdata-item-title h3 a:hover {
    color: #f56140;
}
.layout-newsdata-item-tag {
    display: block;
    height: 20px;
}
.layout-newsdata-item-tag a {
    text-decoration: none;
    color: #888;
    font-weight: normal;
}
