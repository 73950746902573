.layout-news {
    background-color: #F5F5F5;
    padding: 20px 0;
}

.layout-news-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 6px;
}

.layout-news-content h1 {
    text-align: center;
    color: #404040;
}

.layout-news-content p {
    font-size: 18px;
    line-height: 1.5;
    text-indent: 36px;
}

.layout-news-center {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.layout-posi-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 6px;
    font-family: "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.layout-posi-header {
    text-align: center;
    margin-bottom: 30px;
}
.layout-posi-benefits {
    display: flex;
    background-color: #fff;
    margin-bottom: 20px;
    padding: 30px 0;
}
.layout-posi-benefits-title {
    color:#222;
    font-size: 22px;
    font-weight: 400;
    padding: 20px;
}
.layout-posi-benefits-content {
    display: flex;
    flex-wrap: wrap;
    border-left: 1px solid #eee;
    padding: 0px 10px;
    color:#666;
    font-size: 14px;
    line-height: 1.5;
}
.layout-posi-benefits-item {
    display: inline-block;
    width: 100px;
    height: 105px;
    text-align: center;
    position: relative;
    overflow: visible;
}
.layout-posi-benefits-item-pic {
    display: inline-block;
    margin-top: 18px;
}
.layout-posi-benefits-item-pic img {
    width: 36px;
}
.layout-posi-benefits-item-title {
    display: block;
    width: 100%;
    height: 20px;
    line-height: 20px;
    color: #666;
    font-size: 12px;
    text-align: center;
    margin-top: 15px;
    overflow: hidden;
    white-space: nowrap;
}
.layout-posi-list {
    position: relative;
    display: block;
}

.layout-posi-list-card {
    position: relative;
    display: block;
    min-height: 114px;
    padding: 30px 34px 30px 16px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 2px;
}

.layout-posi-list-card-header-title {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    color: #253044;
    padding-bottom: 20px;
}

.layout-posi-list-card-header-detail {
    color: rgba(37, 48, 68, .65);
}

.layout-posi-list-card-content {
    display: block;
    margin-top: 30px;
    border-top: 1px solid #ebebeb;
}

.layout-posi-list-card-content-title {
    padding-top: 40px;
    padding-bottom: 12px;
    font-size: 14px;
    font-weight: 700;
    color: #253044;
}

.layout-posi-list-card-content-detail {
    color: rgba(0, 0, 0, .65);
    font-size: 14px;
    line-height: 1.5;
}

.layout-posi-list-card-content-phone {
    margin-top: 30px;
    background-color: #e6231f;
    color: #fff;
    outline: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}