.layout-products {
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
}
.layout-products-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 30px 10px;
    display: block;
}
.layout-products-container h1 {
    text-align: center;
}
.layout-products-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.layout-product {
    display: block;
    position: relative;
    width: 267px;
    margin: 30px 20px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
}
.layout-product-img {
    width: 265px;
    height: 265px;
    overflow: hidden;
}
.layout-product-img img {
    width: 100%;
    height: 100%;
    padding: auto;
}
.layout-product-title {
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
}
.layout-product-title h2 {
    text-align: center;
    color: #4d4d4d;
}
.layout-product-detail {
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
    padding: 20px;
    background-color: #fff;
}
.layout-product-detail-title {
    display: block;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #f0f0f0;
}
.layout-product-detail-container {
    display: flex;
    width: 355px;

    padding: 15px 10px;
}

.layout-product-detail-container ul {
    list-style: none;
}
.layout-product-detail-container ul li {
    position: relative;
    margin-bottom: 5px;
}
.layout-product-detail-container i.circle {
    top: 2px;
    position: absolute;
    display: block;
    font-style: normal;
    font-size: 12px;
    border-radius: 50%;
    background-color: #DB4437;
    color: #fff;
    height: 18px;
    width: 18px;
    line-height: 18px;
    text-align: center;
}
.layout-product-detail-container span {
    position: relative;
    display: block;
    padding: 0 23px;
}
.layout-product-more {
    margin: 20px;
    background-color: #fff;
}
.layout-product-more table {
    width: 100%;
    border-spacing: 0;
}
.layout-product-more table tr {
    display: table-row;
    
}
.layout-product-more table tr td {
    border-bottom: 1px solid #f0f0f0;
    font-size: 14px;
    height: 55px;
    text-align: center;
}

.layout-product-more table i.circle {
    display: inline-block;
    font-style: normal;
    font-size: 12px;
    border-radius: 50%;
    border: 1px solid #444;
    height: 18px;
    width: 18px;
    line-height: 18px;
}